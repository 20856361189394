import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkNetworkConnection } from '../utils/api';

const useInternetCheck = (interval = 1000) => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkConnection = async () => {
            try {
                const isConnected = await checkNetworkConnection();
                if (!isConnected) {
                    navigate("/nonetwork");
                }
            } catch (error) {
                navigate("/nonetwork");
            }
        };

        const intervalId = setInterval(checkConnection, interval);

        return () => clearInterval(intervalId);
    }, [navigate, interval]);
};

export default useInternetCheck;