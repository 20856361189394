import React, { useEffect } from 'react'
import './ChannelList.css'
import TeacherChannelItem from '../ChannelItem/TeacherChannelItem'
import ParentChannelItem from '../ChannelItem/ParentChannelItem'
import CentreMessages from '../ChannelItem/CentreChatItems/CentreMessages/CentreMessages'

import { Link, useNavigate } from 'react-router-dom'

import InfiniteScroll from 'react-infinite-scroll-component'
import Mixpanel from '../../utils/mixpanel'
import { ANALYTIC_EVENTS, chatTypes } from '../../utils/constants'

const ChannelList = ({
  channels,
  displayMeta,
  isLoading,
  fetchMoreChannels,
  hasMoreChannels
}) => {
  const centreChatChannels = channels?.filter(
    (channel) => channel?.type === chatTypes.PARENT_CENTRE
  )
  const regularChannels = channels?.filter(
    (channel) => channel?.type !== chatTypes.PARENT_CENTRE
  )
  const [loading, setLoading] = React.useState(true)

  useEffect(() => {
    if (isLoading && !channels.length) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [isLoading, channels.length])

  const onChannelClickHandler = (channel) => {
    console.log(channel)
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.THREAD_CLICKED,
      data: {
        teacher_name: channel?.details?.teacher_search_meta?.teacher_name,
        program_name: channel?.details?.parent_display_meta?.chat_title
          ?.split('|')?.[1]
          .trim(),
        child_name: channel?.details?.parent_search_meta?.student_name,
        unread_count: channel?.unread_message_count,
        chat_type: 'teacher'
      }
    })
  }

  const onCentreChatClickHandler = (channel) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.THREAD_CLICKED,
      data: {
        unread_count: channel?.unread_message_count,
        chat_type: 'centre'
      }
    })
  }

  const spinningLoader = (
    <div className="inifinite-scroll-spinner-container">
      <div className="infinite-scroll-spinner"></div>
    </div>
  )

  return loading ? (
    <div className="spinner-container">
      <div className="spinner"></div>
    </div>
  ) : (
    <div className="channel-list">
      {centreChatChannels?.map((centreChatChannel) => (
        <Link
          onClick={() => onCentreChatClickHandler(centreChatChannel)}
          to={{
            pathname: `/centrechat/${centreChatChannel?.uuid}`
          }}
          style={{ textDecoration: 'none' }}>
          <div>
            <CentreMessages
              title={
                centreChatChannel?.details?.parent_display_meta?.chat_title
              }
              channel={centreChatChannel}
              unread_count={centreChatChannel?.unread_message_count}
            />
          </div>{' '}
        </Link>
      ))}

      {!channels?.length && displayMeta === 'teacher_display_meta' ? (
        <div className="no-chats-message">
          <span className="orange-highlight">Oh.</span> you don't have any chats
          at the moment.
        </div>
      ) : (
        <InfiniteScroll
          dataLength={regularChannels.length}
          next={fetchMoreChannels}
          hasMore={hasMoreChannels}
          loader={spinningLoader}
          style={{ overflow: 'hidden' }}>
          {regularChannels.map((channel) =>
            displayMeta === 'teacher_display_meta' ? (
              <TeacherChannelItem key={channel.uuid} channel={channel} />
            ) : (
              <ParentChannelItem
                key={channel.uuid}
                channel={channel}
                onChannelClickHandler={() => onChannelClickHandler(channel)}
              />
            )
          )}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default ChannelList
