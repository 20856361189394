import React from 'react';
import './IFrame.css';

const IframeModal = ({ url, onClose }) => {
    if (!url) return null;

    return (
        <div className="iframe-modal">
            <div className="iframe-modal-content">
                <iframe src={url} title="Iframe Modal" className="iframe" />
                <button className="close-button" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default IframeModal;