import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProfile } from '../../utils/api';
import Spinner from '../../components/Spinner/Spinner';
import './Profile.css';
import backIcon from '../../assets/images/icon_back.svg';
import avatarPoh from '../../assets/images/avatar-poh.svg';
import { logError, logMessage } from '../../sentry';

const ProfilePoh = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(uuid);
        setProfile(data);
      } catch (error) {
        logError(error)
        logMessage('Error fetching profile')
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [uuid]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const renderProfileIcon = () => {
    return <img src={avatarPoh} className="profile-icon" />;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="profile-container">
      <header className="profile-header">
        <button className="back-button" onClick={handleBackClick}>
          <img src={backIcon} alt="Back" />
        </button>
      </header>
      <div className="profile-content">
        <div className="profile-image-container">
          {renderProfileIcon()}
        </div>
        <p className="profile-name">master poh</p>
        <div className="profile-description">
          <div className="description-header">about me</div>
          <div className="description-body">Hi ! I’m Poh. I keep popping up when I have something to share.</div>
        </div>
      </div>
      <div className="profile-footer">
        <button className="go-to-chat-button" onClick={() => navigate(`/chat/${uuid}`)}>go to chat</button>
        <div className="chat-visibility">this chat is also visible to the Oh team</div>
      </div>
    </div>
  );
};

export default ProfilePoh;