import React from 'react';
import { Link } from 'react-router-dom';
import parentIcon from '../../assets/images/icon_parent.svg';
import './ChannelItem.css';

const TeacherChannelItem = ({ channel }) => {
  const meta = channel?.details?.teacher_display_meta;
  const { chat_title = "no title", chat_thumbnail = "", chat_labels = {}, chat_thumbnail_labels = {} } = meta;
  const lastMessage = channel?.last_message || {};
  const { content = { body: '' }, created = '' } = lastMessage;
  const { unread_message_count } = channel;

  const renderChatIcon = () => {
    if (chat_thumbnail) {
      return <img src={chat_thumbnail} alt={chat_title} className="chat-icon" />;
    } else {
      const initials = chat_title?.split(' ')?.map((name) => name?.charAt(0))?.join('');
      return <div className="chat-list-icon-placeholder">{initials}</div>;
    }
  };

  const renderChatThumbnailLabels = () => {
    if (chat_thumbnail_labels && Object.keys(chat_thumbnail_labels).length) {
      return (
        <div className="chat-thumbnail-labels">
          {Object.values(chat_thumbnail_labels)?.join(' | ')}
        </div>
      );
    }
    return null;
  };

  const renderChatLabels = () => {
    if (chat_labels) {
      return (
        <div className="chat-labels">
          {Object.entries(chat_labels)?.map(([key, value], index) => (
            <span key={index} className="chat-label">
              {key === 'parent_name' ? (
                <>
                  <img src={parentIcon} alt="Parent" className="label-icon" />
                  {value}
                </>
              ) : (
                value?.length > 21 ? `${value.substring(0, 21)}...` : value
              )}
            </span>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Link
      to={`/chat/${channel.uuid}`}
      state={{
        chatTitle: chat_title,
        chatIcon: chat_thumbnail,
        chatLabel: chat_labels ? Object.values(chat_labels)?.join(', ') : ''
      }}
      className="channel-item"
    >
      <div className="chat-icon-container">
        {renderChatIcon()}
        {renderChatThumbnailLabels()}
      </div>
      <div className="channel-info">
        <div className="channel-header">
          <h2 className="channel-title">{chat_title}</h2>
          <span className="channel-timestamp">
            {created !== '' ? new Date(created).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : ''}
          </span>
        </div>
        {renderChatLabels()}
        <div className="channel-meta">
          <p className="channel-last-message">{content.body?.length > 35 ? `${content.body?.substring(0, 35)}...` : content.body}</p>
          {unread_message_count > 0 && (
            <span className="unread-badge">{unread_message_count}</span>
          )}
        </div>
      </div>
    </Link>
  );
};

export default TeacherChannelItem;