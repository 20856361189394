// FileMessage.js
import React from 'react'
import fileIcon from '../../assets/images/icon_file.svg'
import downloadIcon from '../../assets/images/icon_download.svg'
import Mixpanel from '../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../utils/constants'
import Linkify from "react-linkify";

const FileMessage = ({ content, openModal, authorType, analyticData }) => {
  const ctaClickHandler = (e) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.MESSAGE_CTA_CLICKED,
      data: {
        body: content.body,
        redirection: content.cta?.[0]?.value,
        ...analyticData
      }
    })
    e.preventDefault()
    openModal(content.cta[0].value)
  }
  return (
    <>
      <p><Linkify>{content.body}</Linkify></p>
      <div className="file-message">
        <img src={fileIcon} alt="file" className="file-icon" />
        <span className="file-name">{content.media.label}</span>
        <a
          href="#"
          className="download-icon"
          onClick={(e) => {
            e.preventDefault()
            openModal(content.media.value)
          }}>
          <img src={downloadIcon} alt="download" />
        </a>
      </div>
      {content.cta && content.cta.length > 0 && (
        <div
          className="cta-container"
          onClick={(e) => {
            e.preventDefault()
            openModal(content.media.value)
          }}>
          <a
            href="#"
            className={`cta-button ${authorType === 'ME' ? 'me' : 'not-me'}`}
            onClick={ctaClickHandler}>
            {content.cta[0].label} →
          </a>
        </div>
      )}
    </>
  )
}

export default FileMessage
