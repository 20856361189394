import React from 'react'
import { useNavigate } from 'react-router-dom'
import './ChatHeader.css'
import backIcon from '../../assets/images/icon_back.svg'
import parentIcon from '../../assets/images/icon_parent.svg'
import { ANALYTIC_EVENTS, chatTypes } from '../../utils/constants'
import Mixpanel from '../../utils/mixpanel'

const ChatHeader = ({
  title,
  titleLabel,
  chatIcon,
  chatLabel,
  channelUuid,
  teacherName,
  type,
  childName
}) => {
  const navigate = useNavigate()
  const program_name = title?.split('|')?.[1]?.trim() ?? title

  const handleBackClick = () => {
    const userType = localStorage.getItem('userType')
    if (userType === 'teacher') {
      navigate('/teacher-home')
    } else if (userType === 'parent') {
      navigate('/parent-home')
    } else {
      navigate(-1) // Fallback in case userType is not set
    }
  }

  const handleHeaderClick = () => {
    navigate({
      pathname: `/profile/${channelUuid}`,
      search: `?child_full_name=${childName}&program_name=${program_name}`
    })
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.PROFILE_CLICKED,
      data: {
        ...(type === chatTypes.PARENT_TEACHER
          ? {
              teacher_name: teacherName,
              program_name: program_name,
              child_name: childName,
              chat_type: 'teacher'
            }
          : {
              chat_type: 'centre'
            })
      }
    })
  }

  const renderChatIcon = () => {
    let initials = ''
    if (chatIcon) {
      return <img src={chatIcon} alt={title} className="chat-icon" />
    } else {
      if (String(title).indexOf(':') !== -1) {
        initials = (teacherName || '')
          .split(' ')
          .map((name) => name.charAt(0))
          .join('')
      } else {
        initials = (title || '')
          .split(' ')
          .map((name) => name.charAt(0))
          .join('')
      }
      return <div className="chat-icon-placeholder">{initials}</div>
    }
  }

  return (
    <header className="chat-header">
      <button className="header-back-button" onClick={handleBackClick}>
        <img src={backIcon} alt="Back" />
      </button>
      <div className="header-container" onClick={handleHeaderClick}>
        <div className="chat-icon-container">{renderChatIcon()}</div>
        <div className="chat-title-container">
          <p className="chat-title">{title?.toLowerCase()}</p>
          {titleLabel && titleLabel.trim() !== '' ? (
            type === chatTypes.PARENT_CENTRE ? (
              <div className="chat-header-active-hours">
                active hours: {titleLabel}
              </div>
            ) : (
              <span className="chat-title-label">
                <img src={parentIcon} className="label-icon-parent" />{' '}
                {titleLabel}
              </span>
            )
          ) : null}
        </div>
      </div>
    </header>
  )
}

export default ChatHeader
