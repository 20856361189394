import React, { useState, useEffect } from 'react';
import { getChannels } from '../../utils/api';
import ChannelList from '../../components/ChannelList/ChannelList';
import { useWebSocket } from '../../context/WebSocketContext';
import useDebounce from '../../hooks/useDebounce';
import searchIcon from '../../assets/images/icon_search.svg';
import closeIcon from '../../assets/images/icon_close.svg';
import searchPlaceholder from '../../assets/images/placeholder-search.svg';
import nosearchResult from '../../assets/images/placeholder-no-search-result.svg';
import './Home.css';
import { logError } from '../../sentry';

const TeacherHome = () => {
  const [channels, setChannels] = useState([]);
  const [hasMoreChannels, setHasMoreChannels] = useState(true);
  const [nextUrl, setNextUrl] = useState(null);
  const [searchNextUrl, setSearchNextUrl] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showChannelLoader, setShowChannelLoader] = useState(false)
  const [showSearch, setShowSearch] = useState(false);
  const [exitSearch, setExitSearch] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [canShowNoSearch, setCanShowNoSearch] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const ws = useWebSocket();

  const fetchChannels = async (searchTerm = '', showLoading = false) => {

    if (showChannelLoader) return;  // Prevent multiple API calls
    setShowChannelLoader(true);

    if (showLoading) {
      setIsLoading(true);
    }

    try {
      const limit = 15;
      const data = await getChannels(searchTerm, limit, searchTerm ? searchNextUrl : nextUrl);

      if ((searchTerm && !searchNextUrl) || (!showSearch && !exitSearch)) {
        // For fresh search, replace the current channels with search results
        // Also replace channels when exiting search (!showSearch && !exitSearch)
        setChannels(data.results);
        setSearchResults(data.results);
      } else if (searchTerm) {
        // For paginated search, append search results
        setChannels((prevChannels) => [...prevChannels, ...data.results]);
        setSearchResults((prevSearchResults) => [...prevSearchResults, ...data.results]);
      } else {
        // For regular infinite scroll, append new results
        setChannels((prevChannels) => [...prevChannels, ...data.results]);
      }

      // Handle pagination for both search and regular results
      if (data.next) {
        if (searchTerm) {
          setSearchNextUrl(data.next); // Set next URL for search pagination
        } else {
          setNextUrl(data.next); // Set next URL for regular pagination
        }
        setHasMoreChannels(true);
      } else {
        setHasMoreChannels(false);
      }

      setError(null);
    } catch (error) {
      logError(error);
      setError('Failed to fetch channels. Please try again.');
    } finally {
      setShowChannelLoader(false);
      if (showLoading) {
        setIsLoading(false);
        sessionStorage.setItem('loaderShown', 'true');
      }
      setCanShowNoSearch(true);
    }
  };

  useEffect(() => {
    // Reset searchNextUrl and searchResults when searchTerm changes
    if (!showSearch && !exitSearch) {
      handleRefresh('')
    }
  }, [showSearch]);

  useEffect(() => {
    // Reset searchNextUrl and searchResults when searchTerm changes
    if (!searchTerm) {
      setSearchResults([]);
      setSearchNextUrl(null);
    }
  }, [searchTerm]);

  useEffect(() => {
    let isMounted = true;
    const loaderShown = sessionStorage.getItem('loaderShown') === 'true';
    fetchChannels('', !loaderShown);

    if (ws) {
      ws.onmessage = (event) => {
        fetchChannels(debouncedSearchTerm, false);
      };
    }

    return () => {
      isMounted = false;
    };
  }, [ws]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      fetchChannels(debouncedSearchTerm, false).then(() => {
        setIsSearching(false);
        setCanShowNoSearch(true);
      });
    }
  }, [debouncedSearchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCanShowNoSearch(false);
  };

  const handleRefresh = async (searchTerm) => {
    await fetchChannels(searchTerm);
  };

  const toggleSearchBar = () => {
    setShowSearch(!showSearch);
    setExitSearch(false)
    setSearchTerm('');
    setSearchResults([]);
    setCanShowNoSearch(false);
    setNextUrl(null)
  };

  return (
    <div className="home-container">
      <header className="home-header">
        {!showSearch ? (
          <>
            <div className="chat-header-home">chat</div>
            <button className="search-icon" onClick={toggleSearchBar}>
              <img src={searchIcon} alt="Search" className="search-icon-image" />
            </button>
          </>
        ) : (
          <div className="search-bar">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="search here"
              className="search-input"
            />
            <button className="close-icon" onClick={toggleSearchBar}>
              <img src={closeIcon} alt="Close" className="close-icon-image" />
            </button>
          </div>
        )}
      </header>
      <div className="content">
        {isLoading || isSearching ? (
          <div className="spinner-container">
            <div className="spinner"></div>
          </div>
        ) : showSearch ? (
          searchTerm === '' ? (
            <div className="search-results-container">
              <img src={searchPlaceholder} alt="no-search-result" />
              <p>search by name, level, milestone to<br />see results</p>
            </div>
          ) : searchResults.length > 0 ? (
            <ChannelList
              channels={searchResults}
              displayMeta="teacher_display_meta"
              isLoading={showChannelLoader}
              fetchMoreChannels={fetchChannels}
              hasMoreChannels={hasMoreChannels}
            />
          ) : (
            canShowNoSearch && (
              <div className="search-results-container">
                <img src={nosearchResult} alt="no-search-result" />
                <p><b>oh. no results found</b></p>
                <p>try searching by name, batch, level</p>
              </div>
            )
          )
        ) : (
          <ChannelList
            channels={channels}
            displayMeta="teacher_display_meta"
            isLoading={showChannelLoader}
            fetchMoreChannels={fetchChannels}
            hasMoreChannels={hasMoreChannels}
          />
        )}
      </div>
      {/* {error && <div className="error-message">{error}</div>} */}
    </div>
  );
};

export default TeacherHome;