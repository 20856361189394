import React from 'react'
import './Message.css'
import TextMessage from './TextMessage'
import ImageMessage from './Moments/ImageMessage'
import VideoMessage from './Moments/VideoMessage'
import FileMessage from './FileMessage'
import ReportMessage from './ReportMessage'
import Iframe from '../IFrame/IFrame'
import { useNavigate } from 'react-router-dom'

import { formatDateString } from '../../helpers'
import readIcon from '../../assets/images/icon_read_reciept.svg'
import avatarPoh from '../../assets/images/avatar-poh.svg'
import PohMessage from './PohMessage'

const MESSAGE_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  FILE: 'file',
  REPORT: 'report',
  POH_V2: 'poh_v2'
}

const MessageContent = ({
  content,
  authorType,
  authorName,
  onOpenModal,
  analyticData,
  message
}) => {
  const messageTypeComponents = {
    [MESSAGE_TYPES.TEXT]: () => (
      <TextMessage
        content={content}
        openModal={onOpenModal}
        authorType={authorType}
        authorName={authorName}
        analyticData={analyticData}
      />
    ),
    [MESSAGE_TYPES.IMAGE]: () => (
      <ImageMessage
        content={content}
        openModal={onOpenModal}
        authorType={authorType}
        analyticData={analyticData}
        message={message}
      />
    ),
    [MESSAGE_TYPES.VIDEO]: () => (
      <VideoMessage
        content={content}
        openModal={onOpenModal}
        authorType={authorType}
        analyticData={analyticData}
        message={message}
      />
    ),
    [MESSAGE_TYPES.FILE]: () => (
      <FileMessage
        content={content}
        openModal={onOpenModal}
        authorType={authorType}
        analyticData={analyticData}
      />
    ),
    [MESSAGE_TYPES.REPORT]: () => (
      <ReportMessage
        content={content}
        openModal={onOpenModal}
        authorType={authorType}
        authorName={authorName}
        analyticData={analyticData}
      />
    )
  }

  const MessageComponent = messageTypeComponents[content?.type]
  return MessageComponent ? <MessageComponent /> : null
}

const MessageAvatar = ({ authorName, authorIcon, onIconClick }) => {
  if (authorIcon) {
    return (
      <img
        src={authorIcon}
        alt={authorName}
        onClick={() => onIconClick(authorName)}
        className="message-icon"
      />
    )
  }

  if (authorName === 'Master Poh') {
    return (
      <img
        src={avatarPoh}
        alt={authorName}
        onClick={() => onIconClick(authorName)}
        className="message-icon"
      />
    )
  }

  const initials = authorName
    .split(' ')
    .map((name) => name.charAt(0))
    .join('')

  return (
    <div
      className="z-50 message-icon-placeholder"
      onClick={() => onIconClick(authorName)}>
      {initials}
    </div>
  )
}

const Message = ({
  message,
  channelUuid,
  teacherName,
  programName,
  childName,
  isCentreChat
}) => {
  const [modalUrl, setModalUrl] = React.useState('')
  const navigate = useNavigate()

  const analyticData = {
    teacher_name: teacherName,
    program_name: programName,
    child_name: childName
  }

  const handleOpenModal = (url) => {
    if (url.startsWith('http')) {
      setModalUrl(url)
    } else {
      navigate(url)
    }
  }

  const handleCloseModal = () => setModalUrl('')

  const handleIconClick = (authorName) => {
    const path =
      authorName === 'Master Poh' ? '/profile/poh/' : `/profile/${channelUuid}`
    navigate(path)
  }

  const authorName = message?.author_name?.split(' ')[0]?.toLowerCase()
  const isMasterPoh = message?.content?.type === MESSAGE_TYPES.POH_V2
  const isMyMessage = message?.author_type === 'ME'
  const isMessageRead = message?.status?.toUpperCase() === 'READ'

  if (isMasterPoh) {
    return (
      <PohMessage
        imageUrl={message?.author_icon}
        messageText={message?.content?.body}
        ctaText={message?.content?.cta[0]?.label}
        ctaLink={message?.content?.cta[0]?.value}
        timestamp={message?.created}
        analyticData={analyticData}
      />
    )
  }

  return (
    <>
      <div
        key={message.uuid}
        className={`message ${isMyMessage ? 'me' : 'not-me'}`}>
        <div
          className={`message-content ${
            isMyMessage ? 'me-content' : 'not-me-content'
          }`}>
          <MessageContent
            content={message.content}
            authorType={message.author_type}
            authorName={message.author_name}
            onOpenModal={handleOpenModal}
            analyticData={analyticData}
            message={message}
          />
        </div>
        <div
          className={`message-timestamp-container ${
            isMyMessage
              ? 'me-timestamp-container'
              : 'not-me-timestamp-container'
          }`}>
          <span className="message-timestamp">
            {formatDateString(message.created)}
          </span>
          {isMyMessage && isMessageRead && (
            <div className="message-read-status">
              <img src={readIcon} alt="read" className="read-status-icon" />
            </div>
          )}
        </div>
      </div>

      {!isMyMessage && !isCentreChat && (
        <div className="author-name">
          {message?.author_name?.toLowerCase() === 'master poh'
            ? 'poh'
            : authorName}
        </div>
      )}

      {modalUrl && <Iframe url={modalUrl} onClose={handleCloseModal} />}
    </>
  )
}

export default Message
