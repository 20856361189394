import React from 'react'
import './CentreMessages.css'
import CentreIcon from '../../../../assets/images/icon_centre.svg'
import readIcon from '../../../../assets/images/icon_read_reciept.svg'

const CentreMessages = ({ title, unread_count, channel }) => {
  const subtitle = 'send us a message'
  const lastMessage = channel?.last_message || {}
  const { content = { body: '' }, created = '' } = lastMessage
  const isMyMessage = lastMessage?.author_type === 'ME'
  const isMessageRead = lastMessage?.status?.toUpperCase() === 'READ'

  const renderChatIcon = () => {
    return <img src={CentreIcon} alt="Centre" className="chat-icon" />
  }

  return (
    <div className="centre-chat-item">
      <div className="chat-icon-container">{renderChatIcon()}</div>

      <div className="centre-chat-container">
        <div className="centre-chat-headings">
          <h2 className="centre-chat-title">{title?.toLowerCase()}</h2>
          <span className="channel-timestamp">
            {created !== ''
              ? new Date(created).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                })
              : ''}
          </span>
        </div>
        <div className="channel-meta">
            <div className="last-message">
              {!isMyMessage && isMessageRead && (
                <div className="message-read-status">
                  <img src={readIcon} alt="read" className="read-status-icon" />
                </div>
              )}
              <p className={content.body.length > 0 ? "channel-last-message" : 'centre-chat-subtitle'}>
                {content.body.length > 35
                  ? `${content.body.substring(0, 35)}...`
                  : content.body.length > 0 ? content.body : subtitle}
              </p>
            </div>
            {unread_count > 0 && content.body.length > 0 && (
              <span className="centre-chat-unread-badge">{unread_count}</span>
            )}
        </div>
        {/* TODO: Add dynamic unread chat count  */}
        {/* {unread_count > 0 && (
          <div className="centre-chat-unread-badge">{unread_count}</div>
        )} */}
      </div>
    </div>
  )
}

export default CentreMessages
