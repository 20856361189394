// VideoMessage.js
import React from 'react'
import Mixpanel from '../../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../../utils/constants'
import './VideoMessage.css'
import playButton from '../../../assets/images/play-icon.png'
import VideoThumbnail from 'react-video-thumbnail';

const VideoMessage = ({ content, openModal, authorType, analyticData, message }) => {

  const [thumbnail, setThumbnail] = React.useState('')

  const ctaClickHandler = (e) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.MESSAGE_CTA_CLICKED,
      data: {
        body: content.body,
        redirection: content.cta?.[0]?.value,
        ...analyticData
      }
    })
    e.preventDefault()
    openModal(content.cta[0].value)
  }

  return (
    <>
      <div className='video-moments-container'>
        <div className='video-container'>
          {/* <img src={playButton} className='video-play-button' />
          <div className='video-thumbnail-container'>
            <VideoThumbnail
              videoUrl={content.media.value}
              thumbnailHandler={(thumbnail) => setThumbnail(thumbnail)}
              // height={80}
              // width={80}
              renderThumbnail={false}
              snapshotAtTime={1}
            />
          </div>
          <img
            src={thumbnail}
            alt="media"
            className="moments-media-preview"
            onClick={(e) => {
              e.preventDefault()
              // openModal(content.media.value)
            }}
          /> */}
          <video
            src={content.media.value + '#t=0.5'}
            className="moments-media-preview"
            onClick={(e) => {
              e.preventDefault()
              // openModal(content.media.value)
            }}
          />
        </div>
        <div className={`moments-vertical-line-${message.author_type === 'ME' ? 'me' : 'not-me'}`} > </div>
        <p className='cta-body'>{content?.body}</p>
      </div>

      {content.cta && content.cta.length > 0 && (
        <div
          className="cta-container"
          onClick={(e) => {
            e.preventDefault()
            openModal(content.media.value)
          }}>
          <a
            href="#"
            className={`cta-button ${authorType === 'ME' ? 'me' : 'not-me'}`}
            onClick={ctaClickHandler}>
            {content.cta[0].label} →
          </a>
        </div>
      )}
    </>
  )
}

export default VideoMessage
