import { useEffect } from 'react'
import { useWebSocket } from '../context/WebSocketContext'
import { useParams } from 'react-router-dom'

const usePresenceUpdate = (interval = 3000) => {
  const ws = useWebSocket()
  const { uuid: channelId } = useParams()

  useEffect(() => {
    if (!ws) return

    const sendPresenceUpdate = () => {
      if (ws.readyState === WebSocket.OPEN) {
        const presenceMessage = {
          presence: true,
          channel_id: channelId
        }
        ws.send(JSON.stringify(presenceMessage))
      }
    }

    // Send presence update immediately if WebSocket is ready
    sendPresenceUpdate()

    // Then set up the interval for subsequent updates
    const presenceInterval = setInterval(sendPresenceUpdate, interval)

    return () => clearInterval(presenceInterval)
  }, [ws, channelId, interval])
}

export default usePresenceUpdate
