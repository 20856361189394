import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { getProfile } from '../../utils/api'
import Spinner from '../../components/Spinner/Spinner'
import './Profile.css'
import backIcon from '../../assets/images/icon_back.svg'
import { logError, logMessage } from '../../sentry'
import { isEmpty, isNull } from 'lodash'
import centreIcon from '../../assets/images/icon_centre.svg'
import Mixpanel from '../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../utils/constants'
import { useSearchParams } from 'react-router-dom'

const Profile = () => {
  const [searchParams] = useSearchParams()
  const childName = searchParams.get('child_full_name')
  const programName = searchParams.get('program_name')

  const { uuid } = useParams()
  const navigate = useNavigate()
  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(uuid)
        setProfile(data)
      } catch (error) {
        logError(error)
        logMessage('Error fetching profile')
      } finally {
        setLoading(false)
      }
    }

    fetchProfile()
  }, [uuid])

  useEffect(() => {
    if (profile) {
      Mixpanel.trackEvent({
        event: ANALYTIC_EVENTS.VIEWED_PROFILE_PAGE,
        data: {
          ...(profile?.active_hours
            ? { chat_type: 'centre' }
            : {
                chat_type: 'teacher',
                program_name: programName,
                child_name: childName,
                teacher_name: profile?.profile_name
              })
        }
      })
    }
  }, [profile])

  const handleBackClick = () => {
    navigate(-1)
  }

  const renderProfileIcon = () => {
    let icon = profile?.profile_icon ?? profile?.icon
    if (isNull(icon) || isEmpty(icon)) {
      icon = centreIcon
    }
    if (icon) {
      return (
        <img
          src={icon}
          alt={profile?.profile_name ?? profile?.name}
          className="profile-icon"
        />
      )
    } else {
      const initials = (profile?.profile_name ?? profile?.name)
        .split(' ')
        .map((name) => name.charAt(0))
        .join('')
      return <div className="profile-icon-placeholder">{initials}</div>
    }
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <div className="profile-container">
      <header className="profile-header">
        <button className="back-button" onClick={handleBackClick}>
          <img src={backIcon} alt="Back" className="back-icon" />
        </button>
      </header>
      <div className="profile-content">
        {/* Icon */}
        <div className="profile-image-container">
          {renderProfileIcon()}
          {profile?.profile_icon_labels && (
            <span className="profile-icon-label">
              {profile?.profile_icon_labels.join(' | ')}
            </span>
          )}
        </div>

        {/* Name and active hours */}
        <p className="profile-name">{profile?.profile_name}</p>
        {profile?.active_hours ? (
          <p className="profile-active-hours">
            active hours: {profile?.active_hours}
          </p>
        ) : null}
        {profile?.profile_labels &&
          Array.isArray(profile.profile_labels) &&
          profile.profile_labels.length > 1 && (
            <p className="profile-details">
              {profile.profile_labels.join(' • ')}
            </p>
          )}

        {/* Bio */}
        <div className="profile-description">
          <div className="description-header">
            {profile?.description_header}
          </div>
          <div className="description-body">{profile?.description_body}</div>
        </div>
      </div>

      {/* Footer (not shown for centre chat profiles) */}
      {!profile?.active_hours ? (
        <div className="profile-footer">
          <div className="chat-visibility">
            this chat is also visible to the Oh team
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Profile
