// ImageMessage.js
import React from 'react'
import Mixpanel from '../../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../../utils/constants'
import './ImageMessage.css'

const ImageMessage = ({ content, openModal, authorType, analyticData, message }) => {

  const ctaClickHandler = (e) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.MESSAGE_CTA_CLICKED,
      data: {
        body: content.body,
        redirection: content.cta?.[0]?.value,
        ...analyticData
      }
    })
    e.preventDefault()
    openModal(content.cta[0].value)
  }

  return (
    <>
      <div className='image-moments-container'>
        <img
          src={content.media.value}
          alt="media"
          className="moments-media-preview"
          onClick={(e) => {
            e.preventDefault()
            // openModal(content.media.value)
          }}
        />
        <div className={`moments-vertical-line-${message.author_type === 'ME' ? 'me' : 'not-me'}`} > </div>
        <p className='cta-body'>{content?.body}</p>
      </div>

      {content.cta && content.cta.length > 0 && (
        <div
          className="cta-container"
          onClick={(e) => {
            e.preventDefault()
            openModal(content.media.value)
          }}>
          <a
            href="#"
            className={`cta-button ${authorType === 'ME' ? 'me' : 'not-me'}`}
            onClick={ctaClickHandler}>
            {content.cta[0].label} →
          </a>
        </div>
      )}
    </>
  )
}

export default ImageMessage
