import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import { logMessage } from '../../sentry'
import { jwtDecode } from 'jwt-decode'
import { getUserInfo } from '../../utils/api'
import Mixpanel from '../../utils/mixpanel'

const Home = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    ;(async () => {
      const token = searchParams.get('token')
      const userType = searchParams.get('userType')
      const channelId = searchParams.get('channelId')

      if (token) {
        localStorage.setItem('token', token)
        localStorage.setItem('userType', userType)
        const decodedToken = jwtDecode(token)
        const userId = decodedToken?.sub

        const userData = await getUserInfo(userId)

        Mixpanel.identifyUser({
          userId,
          name: userData?.full_name,
          ...userData,
          user_type: userType
        })
        if (channelId) {
          navigate(`/chat/${channelId}`)
        } else if (userType === 'teacher') {
          navigate('/teacher-home')
        } else if (userType === 'parent') {
          navigate('/parent-home')
        } else {
          logMessage('No valid role found in token')
        }
      } else {
        logMessage('No token found in URL')
      }
    })()
  }, [navigate, searchParams])

  return (
    <div className="loading-container">
      <Spinner />
    </div>
  )
}

export default Home
