import React, { useState, useRef } from 'react'
import './MessageInput.css'
import sendIcon from '../../assets/images/icon_send_chat.svg'

const MessageInput = ({ sendNewMessage }) => {
  const [message, setMessage] = useState('')
  const [rows, setRows] = useState(1)
  const inputRef = useRef(null)
  const fileInputRef = useRef(null)

  const handleInputChange = (e) => {
    const textareaLineHeight = 20
    const previousRows = e.target.rows
    e.target.rows = 1

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      e.target.rows = currentRows
    }

    if (currentRows >= 5) {
      e.target.rows = 5
      e.target.scrollTop = e.target.scrollHeight
    }

    setRows(currentRows < 5 ? currentRows : 5)
    setMessage(e.target.value)
  }

  const handleSendClick = () => {
    if (message.trim()) {
      const newMessage = {
        type: 'text',
        body: message,
        cta: [],
        media: null
      }
      sendNewMessage(newMessage)
      setMessage('')
      setRows(1)
      inputRef.current?.focus()
    }
  }

  const handleAttachmentClick = () => {
    fileInputRef.current.click()
  }

  const handleFileSelect = (event) => {
    const files = event.target.files
    if (files && files.length > 0) {
      console.log('Selected files:', files)
      // Handle the selected files here
    }
  }

  return (
    <div className="input-container">
      <textarea
        className="message-input"
        placeholder="Type your message..."
        value={message}
        onChange={handleInputChange}
        rows={rows}
        ref={inputRef}
      />

      {/* Hidden file input */}
      {/* <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileSelect}
        style={{ display: 'none' }}
        accept="image/*,video/*,audio/*,.pdf,.doc,.docx"
        multiple
      /> */}

      <div className="button-container">
        {/* <button className="attachment-button" onClick={handleAttachmentClick}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
          </svg>
        </button>*/}
        <button className="send-button" onClick={handleSendClick}>
          <img src={sendIcon} alt="Send" />
        </button>
      </div>
    </div>
  )
}

export default MessageInput
