import React, { useEffect } from 'react';
import './NoNetwork.css';
import noInternetImage from '../../assets/images/placeholder-no-search-result.svg';
import { useNavigate } from "react-router-dom";
import { checkNetworkConnection } from "../../utils/api";

const NoNetwork = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkConnection = async () => {
            try {
                const isConnected = await checkNetworkConnection();
                if (isConnected) {
                    const token = localStorage.getItem('token');
                    navigate(`/?token=${token}`);
                }
            } catch (error) {
                // Handle error if needed
            }
        };

        const intervalId = setInterval(checkConnection, 1000);

        return () => clearInterval(intervalId);
    }, [navigate]);

    return (
        <div className="no-internet-container">
            <img src={noInternetImage} alt="No Internet" className="no-internet-image" />
            <h1>oh. no internet</h1>
            <p>Check your internet connection.<br/>You will be automatically redirected, once you are connected. </p>
            {/*<button className="retry-button">*/}
            {/*    {checkingConnection ? 'Checking...' : 'Retry'}*/}
            {/*</button>*/}
        </div>
    );
};

export default NoNetwork;