export const ANALYTIC_EVENTS = {
  VIEWED_CHAT_PAGE: 'Viewed_Chat_Page',
  THREAD_CLICKED: 'Thread_Clicked',
  VIEWED_THREAD: 'Viewed_Thread',
  MESSAGE_SENT: 'Message_Sent',
  MESSAGE_CTA_CLICKED: 'Message_CTA_Clicked',
  PROFILE_CLICKED: 'Profile_Clicked',
  VIEWED_PROFILE_PAGE: 'Viewed_Profile_Page'
}

export const chatTypes = {
  PARENT_CENTRE: 'PARENT_CENTRE',
  PARENT_TEACHER: 'PARENT_TEACHER'
}
