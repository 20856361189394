import axios from 'axios'
import { logError } from '../sentry'

// Create an axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const appClient = axios.create({
  baseURL: process.env.REACT_APP_HEALTHCHECK_URL + 'api/v1/'
})

const isLocal = process.env.REACT_APP_CUSTOM_NODE_ENV === 'local'

// Add a request interceptor to include the latest token in the headers
apiClient.interceptors.request.use(
  (config) => {
    const { url, method } = config
    // make https if not
    if (url.startsWith('http://') && !isLocal) {
      config.url = config.url.replace('http://', 'https://')
    }

    const token = localStorage.getItem('token')
    const userType = localStorage.getItem('userType')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (token) {
      config.headers.Source = `${userType.toUpperCase()}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

appClient.interceptors.request.use(
  (config) => {
    const { url, method } = config
    // make https if not
    if (url.startsWith('http://') && !isLocal) {
      config.url = config.url.replace('http://', 'https://')
    }

    const token = localStorage.getItem('token')
    const userType = localStorage.getItem('userType')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (token) {
      config.headers.Source = `${userType.toUpperCase()}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const getChannels = async (
  searchTerm = '',
  limit = 1000,
  nextUrl = null,
) => {
  let url

  // If a fresh search is being initiated, ignore nextUrl and use searchTerm
  if (searchTerm && !nextUrl) {
    url = `chats/?search=${searchTerm}&page_size=${limit}`
  } else if (nextUrl) {
    // If no search term but nextUrl exists, use nextUrl
    url = nextUrl
  } else {
    // If no searchTerm and no nextUrl, it's a regular first load
    url = `chats/?page_size=${limit}`
  }

  const response = await apiClient.get(url)
  return response.data
}

export const getMessages = async (channelId, nextUrl) => {
  let url
  if (nextUrl) url = nextUrl
  else url = `messages/${channelId}/?page_size=15`
  const response = await apiClient.get(url)
  return response.data
}

export const getProfile = async (userId) => {
  const response = await apiClient.get(`member-profile/${userId}/`)
  return response.data
}

export const getChatHeader = async (chatUuid) => {
  const response = await apiClient.get(`chat-header/${chatUuid}/`)
  return response.data
}

export const checkNetworkConnection = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_HEALTHCHECK_URL)
    if (response.status === 200) {
      return true
    }
  } catch (error) {
    logError(error)
    return false
  }
  return false
}

//get total unread messages across all chats
export const getUnreadMessages = async () => {
  const response = await apiClient.get('unread-messages/')
  return response.data
}

export const getPreferredCentre = async () => {
  const response = await apiClient.get('preferred-center/')
  return response.data
}

//get parent user information
export const getUserInfo = async (userId) => {
  const response = await appClient.get(`base/user/${userId}/`)
  return response.data
}
