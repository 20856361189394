import React, { useState, useEffect, useRef } from 'react'
import ChatHeader from '../../../components/ChatHeader/ChatHeader'
import MessageList from '../../../components/MessageList/MessageList'
import MessageInput from '../../../components/MessageInput/MessageInput'
import { useParams, useSearchParams } from 'react-router-dom'
import { getMessages, getChatHeader } from '../../../utils/api'
import usePresenceUpdate from '../../../hooks/usePresenceUpdate'
import './../Chat.css'
import { useWebSocket } from '../../../context/WebSocketContext'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { logError, logMessage } from '../../../sentry'
import { isEmpty, isNil } from 'lodash'
import Mixpanel from '../../../utils/mixpanel'
import { ANALYTIC_EVENTS } from '../../../utils/constants'
import centreIcon from '../../../assets/images/icon_centre.svg'

const CentreChat = () => {
  const { uuid } = useParams()
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [nextUrl, setNextUrl] = useState(null)
  const [hasMoreData, setHasMoreData] = useState(true)
  const [chatHeader, setChatHeader] = useState({})
  const ws = useWebSocket()

  usePresenceUpdate()
  const [searchParams] = useSearchParams()
  const child_full_name = searchParams.get('childName')
  const messageContainerRef = useRef(null)

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      console.log('scrollheight', messageContainerRef.current.scrollHeight)
      setTimeout(() => {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight
      }, 100)
    }
  }

  const fetchMessages = async () => {
    try {
      const data = await getMessages(uuid, nextUrl)

      if (!nextUrl) {
        // If it's the first call or there's no pagination, replace the current messages
        setMessages(
          data.results.sort((a, b) => new Date(b.created) - new Date(a.created))
        )
      } else {
        // Append new messages for paginated results
        setMessages((prevMessages) =>
          [...prevMessages, ...data.results].sort(
            (a, b) => new Date(b.created) - new Date(a.created)
          )
        )
      }

      if (data.next) {
        setNextUrl(data.next)
        setHasMoreData(true)
      } else {
        setHasMoreData(false)
      }
    } catch (error) {
      logError(error)
      logMessage('Failed to fetch messages')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchMessages()
  }, [uuid])

  const hasTrackedViewedThread = useRef(false)

  useEffect(() => {
    if (
      !loading &&
      !isEmpty(messages) &&
      chatHeader &&
      !hasTrackedViewedThread.current
    ) {
      Mixpanel.trackEvent({
        event: ANALYTIC_EVENTS.VIEWED_THREAD,
        data: {
          chat_type: 'centre'
        }
      })
      hasTrackedViewedThread.current = true
    }
  }, [messages, chatHeader, loading])

  useEffect(() => {
    const fetchChatHeader = async () => {
      try {
        const data = await getChatHeader(uuid)
        setChatHeader(data)
      } catch (error) {
        logError(error)
        logMessage('Error fetching chat header')
      }
    }

    fetchChatHeader()
  }, [uuid])

  useEffect(() => {
    if (ws) {
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data)
        handleNewMessage(message)
      }
    }
  }, [ws])

  const handleNewMessage = (newMessage) => {
    if (newMessage?.presence) {
      if (newMessage?.channel_id === uuid) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.status !== 'READ' ? { ...msg, status: 'READ' } : msg
          )
        )
      }
    } else if (!isNil(newMessage?.content)) {
      setMessages((prevMessages) => {
        const exists = prevMessages.some(
          (message) => message.uuid === newMessage.uuid
        )
        if (!exists) {
          return [newMessage, ...prevMessages]
        }
        return prevMessages
      })
    }
  }

  const sendNewMessage = (newMessage) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.MESSAGE_SENT,
      data: {
        body: newMessage?.body,
        chat_type: 'centre'
      }
    })
    if (ws) {
      ws.send(
        JSON.stringify({
          channel_id: uuid,
          message: newMessage
        })
      )
    }
    const formattedNewMessage = {
      uuid: uuidv4(),
      content: newMessage,
      status: 'sent',
      created: dayjs().toISOString(),
      author_uuid: '',
      author_type: 'ME',
      author_icon: '',
      author_name: '',
      channel_id: ''
    }
    setMessages((prevMessages) => [formattedNewMessage, ...prevMessages])
    scrollToBottom()
  }
  return (
    <div className="chat-container">
      <ChatHeader
        title={chatHeader.title}
        titleLabel={chatHeader.title_label}
        type={chatHeader?.type}
        chatIcon={centreIcon}
        chatLabel={chatHeader.icon_label}
        channelUuid={uuid}
        teacherName={chatHeader.teacher_name}
      />
      <MessageList
        messages={messages}
        fetchMessages={fetchMessages}
        hasMoreData={hasMoreData}
        channelUuid={uuid}
        teacherName={chatHeader.teacher_name}
        childName={child_full_name}
        programName={chatHeader?.title?.split(':')?.[0]}
        messageContainerRef={messageContainerRef}
        isCentreChat={true}
      />
      <MessageInput sendNewMessage={sendNewMessage} />
    </div>
  )
}

export default CentreChat
