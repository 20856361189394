import * as Sentry from '@sentry/react'

export const initializeSentry = () => {
  const environment = process.env.REACT_APP_CUSTOM_NODE_ENV

  if (environment !== 'local') {
    Sentry.init({
      dsn: "https://5085a6e77dde8e7ae4c8e3b26ef0a77d@o343131.ingest.us.sentry.io/4507905624375296",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment: environment,
      attachStacktrace: true,
      tracesSampleRate: 1.0,
      //session replay
      replaysSessionSampleRate: 0.1, // 10% in production
      replaysOnErrorSampleRate: 1.0, // 100% on error
    })
  }
}

export const logError = (exception) => {
  Sentry.captureException(exception)
  console.error(exception)
}

export const logMessage = (message) => {
  Sentry.captureMessage(message)
  console.log(message)
}

export const setSentryUser = (userId) => {
  if (userId) {
    Sentry.setUser({ id: userId })
  }
}