import mixpanel from 'mixpanel-browser'
import { isEmpty } from 'lodash'
import { logMessage } from '../sentry'

const Mixpanel = {
  init: (token) => {
    try {
      mixpanel.init(token, {
        debug: true, // Comment on production
        loaded: function () {
          logMessage('Mixpanel initialized successfully')
        }
      })
    } catch (error) {
      console.error('Failed to initialize Mixpanel:', error)
      logMessage('Failed to initialize Mixpanel')
    }
  },

  identifyUser: (userData) => {
    if (!isEmpty(userData)) {
      const userId = userData?.userId
      console.log(userData)

      try {
        mixpanel.identify(userId)
        mixpanel.people.set(userData)

        logMessage(`Mixpanel user identified, ${userId}`)
      } catch (error) {
        console.error('Failed to identify user in Mixpanel:', error)
        logMessage(`Failed to identify Mixpanel user: ${userId}`)
      }
    } else {
      console.warn('Empty user data provided to identifyUser')
      logMessage('Empty user data provided to identifyUser')
    }
  },

  trackEvent: ({ event, data }) => {
    const userType = localStorage.getItem('userType')
    if (userType === 'parent') {
      console.log('trackEvent', event, data)
      mixpanel.track(event, data)
    }
  }
}

export default Mixpanel
