export const formatDateString = (dateTimeStamp) => {
  const date = new Date(dateTimeStamp)

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })

  const formattedDate = date.toLocaleDateString('en-GB', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).replace(',', ' •')

  return `${formattedTime} • ${formattedDate}`

}