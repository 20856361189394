import React from 'react'
import { Link } from 'react-router-dom'
import './ChannelItem.css'
import readIcon from '../../assets/images/icon_read_reciept.svg'


const ParentChannelItem = ({ channel, onChannelClickHandler }) => {
  const child_full_name = channel?.details?.parent_search_meta?.student_name
  const meta = channel?.details?.parent_display_meta
  const {
    chat_title = 'no title',
    chat_thumbnail = '',
    chat_labels = null
  } = meta
  const lastMessage = channel?.last_message || {}
  const { content = { body: '' }, created = '' } = lastMessage
  const { unread_message_count } = channel
  const isMyMessage = lastMessage?.author_type === 'ME'
  const isMessageRead = lastMessage?.status?.toUpperCase() === 'READ'

  const renderChatIcon = () => {
    if (chat_thumbnail) {
      return <img src={chat_thumbnail} alt={chat_title} className="chat-icon" />
    } else {
      const initials = chat_title
        ?.split(' ')
        ?.map((name) => name?.charAt(0))
        ?.join('')
        ?.substring(0, 2)
      return <div className="chat-list-icon-placeholder">{initials}</div>
    }
  }

  const renderChatLabels = () => {
    if (chat_labels) {
      return (
        <div className="chat-labels">
          {Object.entries(chat_labels)
            ?.filter(([key]) => key === 'student_name')
            .map(([key, value], index) => (
              <span key={index} className="chat-label">
                {/* <img src={teacherIcon} alt="Parent" className="label-icon" /> */}
                {value}
              </span>
            ))}
        </div>
      )
    }
    return null
  }

  return (
    <Link
      to={{
        pathname: `/chat/${channel.uuid}`,
        search: `childName=${child_full_name}`
      }}
      state={{
        chatTitle: chat_title,
        chatIcon: chat_thumbnail,
        chatLabel: chat_labels ? Object.values(chat_labels)?.join(', ') : ''
      }}
      className="channel-item"
      onClick={onChannelClickHandler}>
      <div className="chat-icon-container">{renderChatIcon()}</div>
      <div className="channel-info">
        <div className="channel-header">
          <h2 className="channel-title">{chat_title}</h2>
          <span className="channel-timestamp">
            {created !== ''
              ? new Date(created).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                })
              : ''}
          </span>
        </div>
        {renderChatLabels()}
        <div className="channel-meta">
        <div className="last-message">
          {!isMyMessage && isMessageRead && (
            <div className="message-read-status">
              <img src={readIcon} alt="read" className="read-status-icon" />
            </div>
          )}
          <p className="channel-last-message">
            {content.body.length > 35
              ? `${content.body.substring(0, 35)}...`
              : content.body}
          </p>
          </div>
          {unread_message_count > 0 && (
            <span className="unread-badge">{unread_message_count}</span>
          )}
        </div>
      </div>
    </Link>
  )
}

export default ParentChannelItem
