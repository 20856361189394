import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
    const [ws, setWs] = useState(null);
    const attemptsRef = useRef(0);
    const maxAttempts = 3;
    const token = localStorage.getItem('token');
    const location = useLocation();

    const connectWebSocket = () => {
        if (location.pathname === '/') {
            //console.log('WebSocket connection skipped on /home route');
            return;
        }

        const wsURL = `${process.env.REACT_APP_WS_URL}?token=${token}`;
        const socket = new WebSocket(wsURL);

        socket.onopen = () => {
            //console.log('WebSocket connected');
            attemptsRef.current = 0;
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        socket.onclose = () => {
            //console.log('WebSocket closed');
            if (attemptsRef.current < maxAttempts) {
                attemptsRef.current += 1;
                setTimeout(connectWebSocket, 2000);
            } else {
                console.error('WebSocket reconnection failed');
            }
        };

        setWs(socket);
    };

    useEffect(() => {
        if (location.pathname !== '/') {
            connectWebSocket();
        }

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [token, location.pathname]);

    useEffect(() => {
        if (location.pathname !== '/') {
            const heartbeatInterval = setInterval(() => {
                if (ws && ws.readyState === WebSocket.OPEN) {
                    const heartbeatPayload = { heartbeat: true };
                    ws.send(JSON.stringify(heartbeatPayload));
                }
            }, 10000);

            return () => {
                clearInterval(heartbeatInterval);
            };
        }
    }, [ws, location.pathname]);

    return (
        <WebSocketContext.Provider value={ws}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};