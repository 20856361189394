import React from 'react'
import './PohMessage.css'
import { formatDateString } from '../../helpers';
import Mixpanel from '../../utils/mixpanel';
import { ANALYTIC_EVENTS } from '../../utils/constants';


const PohMessage = ({ imageUrl, messageText, timestamp, ctaText = '', ctaLink = '', analyticData }) => {

  const ctaClickHandler = (e) => {
    Mixpanel.trackEvent({
      event: ANALYTIC_EVENTS.MESSAGE_CTA_CLICKED,
      data: {
        body: messageText,
        redirection: ctaLink,
        ...analyticData
      }
    })
  }
  return (
    <div className="poh-message-container">
      <div className='line'></div>
      <div className='avatar-message'>
        <img className="avatar" src={imageUrl} alt="avatar" />
        <div className="poh-message-content">
          <div className="message-text">
            {(messageText)} <a href={ctaLink} onClick={ctaClickHandler} className="link-text">{ctaText.toLowerCase()}</a>
          </div>
          <div className="timestamp">{formatDateString(timestamp)}</div>
        </div>
      </div>
      <div className='line'></div>
    </div>
  );
};

export default PohMessage